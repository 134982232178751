import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSingleCategoryData } from '../../../redux/features/SingleStore/singleCategorySlice';
import { Grid, Skeleton } from '@mui/material';
import SingleProduct from '../Products/SingleProduct';
import DropDown from '../../ReusableComponent/DropDown';
import Validation from '../../../Constants/Validation';
import { fetchWishlistData } from '../../../redux/features/Wishlist/wishlistSlice';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import { createTheme } from '@mui/material/styles';
import './../../../Styles/customSingleStore.css';
// import './../../customSingleStore.css';
import useMediaQueryMaterial from '@mui/material/useMediaQuery';
import 'swiper/css';
import 'swiper/css/virtual';
import { Virtual, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
const SingleCategory = ({
  categorysId,
  orderMethod,
  merchantId,
  categoryName,
}) => {
  const { percentOffItem, isValidJSON, filterProductData, isDecodedData } =
    Validation();
  const [singleCategoryData, setSingleCategoryData] = useState([]);
  const [sortMethod, setSortMethod] = useState();
  const [allsortedProducts, setAllSortedProducts] = useState();
  const singleCategoryDataState = useSelector((state) => state.singleCategory);
  const authUserData = useSelector((state) => state.authUser.UserData);
  const [singleStoreData, setSingleStoreData] = useState();
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const isMobile = useMediaQueryMaterial('(max-width:768px)');
  const SingleStoreDataState = useSelector((state) => state.storeProductData);
  const dispatch = useDispatch();

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 856,
        md: 1200,
        lg: 1500,
        xl: 1700,
        xxl: 1920,
      },
    },
  });

  // const xxlscreen = useMediaQuery({ query: '(min-width: 1960px)' });
  // const xlscreen = useMediaQuery({ query: '(min-width: 1700px)' });
  // const xscreen = useMediaQuery({ query: '(min-width: 1500px)' });
  // const sscreen = useMediaQuery({ query: '(min-width: 1200px)' });

  const xxlscreen = useMediaQuery({ query: '(min-width: 1920px)' });
  const xlscreen = useMediaQuery({ query: '(min-width: 1700px)' });
  const lgcreen = useMediaQuery({ query: '(min-width: 1500px)' });
  const smcreen = useMediaQuery({ query: '(min-width: 1200px)' });
  const xscreen = useMediaQuery({ query: '(min-width: 854px)' });
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    // slidesToShow: xxlscreen ? 7 : xlscreen ? 5 : xscreen ? 5 : sscreen ? 5 : 2,
    slidesToShow: xxlscreen
      ? 7
      : xlscreen
      ? 6
      : lgcreen
      ? 5
      : smcreen
      ? 5
      : xscreen
      ? 3
      : 2,
    slidesToScroll: 2,
  };
  const handleFilterChange = (val) => {
    setSortMethod(val);
  };
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const sortProducts = (products, method) => {
    if (!Array.isArray(products)) {
      return [];
    }

    switch (method) {
      case method === undefined:
        return products;
      case 'p_lth':
        return [...products]
          .slice()
          .sort((a, b) => parseInt(a.price, 10) - parseInt(b.price, 10));

      case 'p_htl':
        return [...products]
          .slice()
          .sort((a, b) => parseInt(b.price, 10) - parseInt(a.price, 10));

      case 'd_htl':
        return [...products].slice().sort((a, b) => {
          return (
            percentOffItem(parseInt(b.price), parseInt(b.compare_price)) -
            percentOffItem(parseInt(a.price), parseInt(a.compare_price))
          );
        });
      default:
        return products;
    }
  };

  useEffect(() => {
    // const sortedProducts = sortProducts(
    //   singleCategoryData && singleCategoryData[categorysId],
    //   sortMethod,
    // );
    const sortedProducts = sortProducts(
      singleCategoryData && singleCategoryData,
      sortMethod,
    );

    // Check if sorting is not happening or if the method is not "d_htl"
    const shouldNotSortDiscount = sortMethod === undefined;
    // console.log('category products: ', sortedProducts);
    // if (sortedProducts && shouldNotSortDiscount) {
    setAllSortedProducts(sortedProducts);
    // }

    goToTop();
  }, [sortMethod, singleCategoryData, categorysId]);

  const datawishlist = {
    id: authUserData && authUserData.id,
    merchant_id: merchantId,
  };

  useEffect(() => {
    if (!SingleStoreDataState.loading) {
      setSingleStoreData(
        SingleStoreDataState &&
          SingleStoreDataState.storeProductData &&
          SingleStoreDataState.storeProductData.all_product,
      );
    }
  }, [
    SingleStoreDataState.loading,
    SingleStoreDataState.storeProductData.all_product,
  ]);

  useEffect(() => {
    const localData = localStorage.getItem('recent' + merchantId);
    if (isValidJSON(isDecodedData(localData))) {
      const storedRecentlyViewed =
        isValidJSON(
          isDecodedData(localStorage.getItem('recent' + merchantId)),
        ) || [];
      if (merchantId && storedRecentlyViewed && singleStoreData) {
        setRecentlyViewed(
          filterProductData(storedRecentlyViewed, singleStoreData),
        );
        // console.log(filterProductData(storedRecentlyViewed, singleStoreData))
      }
    } else {
      // console.log('not valid json');
    }
  }, [singleStoreData, categorysId, orderMethod, merchantId, categoryName]);

  useEffect(() => {
    if (authUserData && authUserData.id) {
      dispatch(fetchWishlistData(datawishlist));
    }
  }, [authUserData, categorysId]);

  useEffect(() => {
    const data = {
      merchant_id: merchantId,
      orderMethod: orderMethod,
      category_id: categorysId,
    };
    if (categorysId && orderMethod) {
      // console.log(categorysId)
      dispatch(fetchSingleCategoryData(data));
    }
  }, [categorysId, orderMethod, merchantId]);

  useEffect(() => {
    if (!singleCategoryDataState.loading) {
      const categoryData =
        singleCategoryDataState &&
        singleCategoryDataState.categoryData &&
        singleCategoryDataState?.categoryData?.all_product;

      const categoryAllProducts = Object.values(categoryData ?? [])[0];
      const filteredProducts = categoryAllProducts?.filter((product) => {
        // console.log('product', product)
        // return true;
        const isPickup = product.show_type == '1' || product.show_type == '0';
        const isDelivery =
          product.show_type == '2' || product.show_type == '0';

        // console.log(
        //   'here => ',
        //   orderMethod,
        //   isPickup,
        //   isDelivery,
        //   product.show_type,
        // );

        if (orderMethod === 'pickup' && isPickup) return true;
        if (orderMethod === 'delivery' && isDelivery) return true;

        const pickupProduct = orderMethod === 'pickup' && isPickup;
        const deliveryProduct = orderMethod === 'delivery' && isDelivery;
        return pickupProduct || deliveryProduct;
      });

      setSingleCategoryData(filteredProducts);
    }
  }, [singleCategoryDataState.loading, singleCategoryDataState, categorysId]);

  return (
    <>
      {!singleCategoryDataState.loading && singleCategoryDataState.error ? (
        <div> Error : {singleCategoryDataState.error} </div>
      ) : null}
      <div className="single-selectCategory-product-listing">
        <div className="q-single-category-headings">
          <span> {categoryName && categoryName} </span>

          <DropDown
            onFilterChange={handleFilterChange}
            categorysId={categorysId}
          />
        </div>
        {isMobile ? (
          <Grid
            container
            className={` ${singleCategoryDataState.loading ? 'loading' : ''}`}
          >
            {singleCategoryDataState.loading ? (
              <>
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />
              </>
            ) : (
              allsortedProducts &&
              allsortedProducts.map((product) => (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={2}
                  key={product.id}
                  className="product-in-category"
                >
                  <SingleProduct
                    key={product.id}
                    product={product}
                    categoryId={categorysId}
                    Wishlist={false}
                    merchantId={product.merchant_id}
                  />
                </Grid>
              ))
            )}
          </Grid>
        ) : (
          
          <div
            container 
            className={`row ${
              singleCategoryDataState.loading ? 'loading' : ''
            }`}
          >
            {/* <div>{singleCategoryDataState}</div> */}
            {singleCategoryDataState.loading ? (
              <>
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />{' '}
                <Skeleton
                  variant="rounded"
                  width={211}
                  height={290}
                  style={{ margin: '10px' }}
                />
              </>
            ) : (
              allsortedProducts &&
              allsortedProducts.map((product) => (
                <Grid
                  item
                  theme={theme}
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2.4}
                  xl={2}
                  xxl={1.7}
                  key={product.id}
                  className="product-in-category"
                >
                  <SingleProduct
                    key={product.id}
                    product={product}
                    categoryId={categorysId}
                    Wishlist={false}
                    merchantId={product.merchant_id}
                  />
                </Grid>
              ))
            )}
          </div>
        )}
      </div>
      {recentlyViewed && recentlyViewed.length > 0 && (
        <div className="quickvee-highest-selling-product-section">
          <span>Recently Viewed Products </span>
          {isMobile ? (
            <Swiper
              slidesPerView={2}
              spaceBetween={8}
              // initialSlide={1}
              // centeredSlides={false}
              modules={[FreeMode, Virtual]}
              freeMode={true}
              scrollbar={{ draggable: true, dragSize: 24 }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                534: {
                  slidesPerView: 2.3,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                769: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="media-scroller-upper"
            >
              <div className="media-scroller">
                {recentlyViewed.map((product, index) => (
                  <SwiperSlide className="media-element">
                    <SingleProduct
                      key={product.productId}
                      product={product.product}
                      categoryId={product.categoryId}
                      merchantId={merchantId}
                    />
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          ) : (
            <Slider {...settings}>
              {recentlyViewed.map((product, index) => (
                <SingleProduct
                  key={product.productId}
                  product={product.product}
                  categoryId={product.categoryId}
                  merchantId={merchantId}
                />
              ))}
            </Slider>
          )}
        </div>
      )}
    </>
  );
};

export default SingleCategory;
